const languages = [
    {
        id: 1,
        name: "en",
        image: "/img/en.png"
    },
    {
        id: 2,
        name: "ru",
        image: "/img/ru.png"
    },
    {
        id: 3,
        name: "uz",
        image: "/img/uz.png"
    },
]

export  {languages}