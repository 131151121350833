// import HeaderBoxCard from "/im"

import Carousel1 from "../images/headerCarousel/Carousel1.jpg";
import Carousel2 from "../images/headerCarousel/Carousel2.jpg";
import Carousel3 from "../images/headerCarousel/Carousel3.jpg";
import Carousel4 from "../images/headerCarousel/Carousel4.jpg";
import Carousel5 from "../images/headerCarousel/Carousel5.jpg";

import Certificate1 from "../images/certificate1.jpg";
import Certificate2 from "../images/certificate2.jpg";
import Certificate3 from "../images/certificate3.jpg";
import Certificate4 from "../images/certificate4.jpg";
import Certificate5 from "../images/certificate5.jpg";
import Certificate6 from "../images/certificate6.jpg";

export const HeaderCarousel = [
  {
    // id: 1,
    // title: "hero-slider-heading",
    // text: "hero-slider-description",
    img: Carousel1,
  },
  {
    // id: 1,
    // title: "hero-slider-heading",
    // text: "hero-slider-description",
    img: Carousel2,
  },
  {
    // id: 1,
    // title: "hero-slider-heading",
    // text: "hero-slider-description",
    img: Carousel3,
  },
  {
    // id: 1,
    // title: "hero-slider-heading",
    // text: "hero-slider-description",
    img: Carousel4,
  },
  {
    // id: 1,
    // title: "hero-slider-heading",
    // text: "hero-slider-description",
    img: Carousel5,
  },
];

export const MenuData = [
  {
    title: "home",
    path: "/",
  },
  {
    title: "about",
    path: "/about",
  },
  {
    title: "products",
    path: "/product",
  },
  {
    title: "vacancy",
    path: "/vacancy",
  },
  {
    title: "news",
    path: "/news",
  },
  {
    title: "contact",
    path: "/contact",
  },
];

export const HeaderBoxData = [
  {
    id: 1,
    title_uz: "Devorlarni bo'yash",
    title_ru: "Покраска стен",
    img: "/img/homeCard/1.jpg",
  },
  {
    id: 2,
    title_uz: "Qandlillarni osish",
    title_ru: "Зажгите люстры",
    img: "/img/homeCard/2.jpg",
  },
];

// export const BestSellerData = [
//   {
//     id: 1,
//     img: "https://emaks.com.tr/index-slider/ay3.png",
//     hoverImg: "https://emaks.com.tr/index-slider/ay1.png",
//     title: "Grid Sideboard",
//     price: 120,
//     percentage: 20,
//   },
//   {
//     id: 2,
//     img: "https://emaks.com.tr/index-slider/ay1.png",
//     hoverImg: "https://emaks.com.tr/index-slider/ku2.png",
//     title: "Grid Sideboard",
//     price: 120,
//     percentage: 20,
//   },
//   {
//     id: 3,
//     img: "https://emaks.com.tr/index-slider/as3.png",
//     hoverImg: "https://emaks.com.tr/index-slider/as2.png",
//     title: "Grid Sideboard",
//     price: 120,
//     percentage: 20,
//   },
//   {
//     id: 4,
//     img: "https://emaks.com.tr/index-slider/ku1.png",
//     hoverImg: "https://emaks.com.tr/index-slider/ku4.png",
//     title: "Grid Sideboard",
//     price: 120,
//     percentage: 20,
//   },
//   {
//     id: 5,
//     img: "https://emaks.com.tr/index-slider/as4.png",
//     hoverImg: "https://emaks.com.tr/index-slider/as4.png",
//     title: "Grid Sideboard",
//     price: 120,
//     percentage: 20,
//   },
//   {
//     id: 6,
//     img: "https://emaks.com.tr/index-slider/as5.png",
//     hoverImg: "https://emaks.com.tr/index-slider/ku2.png",
//     title: "Grid Sideboard",
//     price: 120,
//     percentage: 20,
//   },
// ];

// export const NewCollectionData = [
//   {
//     id: 1,
//     video: Video1,
//     title: "Retro Bliss",
//   },
//   {
//     id: 2,
//     video: Video2,
//     title: "Now or Never",
//   },
//   {
//     id: 3,
//     video: Video3,
//     title: "Perfect Hormony",
//   },
//   {
//     id: 4,
//     video: Video4,
//     title: "Primo",
//   },
// ];

// export const LastestNewsCardData = [
//   {
//     id: 1,
//     img: "https://emaks.com.tr/index-slider/ay3.png",
//     hoverImg: "https://emaks.com.tr/index-slider/ay1.png",
//     title: "Grid Sideboard",
//     price: 120,
//   },
//   {
//     id: 2,
//     img: "https://emaks.com.tr/index-slider/ay1.png",
//     hoverImg: "https://emaks.com.tr/index-slider/ku2.png",
//     title: "Grid Sideboard",
//     price: 120,
//   },
//   {
//     id: 3,
//     img: "https://emaks.com.tr/index-slider/as3.png",
//     hoverImg: "https://emaks.com.tr/index-slider/as2.png",
//     title: "Grid Sideboard",
//     price: 120,
//   },
//   {
//     id: 4,
//     img: "https://emaks.com.tr/index-slider/ku1.png",
//     hoverImg: "https://emaks.com.tr/index-slider/ku4.png",
//     title: "Grid Sideboard",
//     price: 120,
//   },
//   {
//     id: 5,
//     img: "https://emaks.com.tr/index-slider/as4.png",
//     hoverImg: "https://emaks.com.tr/index-slider/as4.png",
//     title: "Grid Sideboard",
//     price: 120,
//   },
//   {
//     id: 6,
//     img: "https://emaks.com.tr/index-slider/as5.png",
//     hoverImg: "https://emaks.com.tr/index-slider/ku2.png",
//     title: "Grid Sideboard",
//     price: 120,
//   },
// ];

export const PartnerData = [
  {
    img: "https://thumb.tildacdn.com/tild6632-6464-4833-a130-386434326131/-/resize/320x/-/format/webp/11540951_16250908477.jpg",
  },
  {
    img: "https://thumb.tildacdn.com/tild3663-6661-4665-a566-343530643161/-/resize/320x/-/format/webp/71113121_12864583518.jpg",
  },
  {
    img: "https://thumb.tildacdn.com/tild6638-3766-4632-b437-366439313139/-/resize/320x/-/format/webp/22279937_19629359424.jpg",
  },
  {
    img: "https://thumb.tildacdn.com/tild6438-6566-4461-b663-376664653863/-/resize/320x/-/format/webp/67205310_13926844675.jpg",
  },
  {
    img: "https://thumb.tildacdn.com/tild3365-3630-4133-b965-326366376134/-/resize/320x/-/format/webp/20638324_14532526647.jpg",
  },
  {
    img: "https://thumb.tildacdn.com/tild3065-6637-4561-b031-366263643362/-/resize/320x/-/format/webp/69714079_29707516229.png",
  },
  {
    img: "https://thumb.tildacdn.com/tild6261-3639-4333-b732-393166376135/-/resize/320x/-/format/webp/logo_ixlosmebel-2.png",
  },
  {
    img: "https://thumb.tildacdn.com/tild3463-3164-4639-b734-636133653763/-/resize/320x/-/format/webp/21056209_19480011554.png",
  },
  {
    img: "https://thumb.tildacdn.com/tild6636-3334-4165-b762-666334623236/-/resize/320x/-/format/webp/21728742_11707223233.jpg",
  },
];

export const AboutPageProductData = [
  {
    img: "/img/about-p-1.jpg",
    title: "about-p-1-head",
    text: "about-p-1-desc",
   
  },
  {
    img: "/img/about-p-2.jpg",
    title: "about-p-2-head",
    text: "about-p-2-desc",
   
  },
  {
    img: "/img/about-p-3.jpg",
    title: "about-p-3-head",
    text: "about-p-3-desc",
   
  },
];

// export const SingleProductImageData = [
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-04_retro_Bliss_04-150x150.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-20_Retro_Bliss_20.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-34_Retro_Bliss_34-150x150.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//   },
//   //
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-75_Retro_Bliss_75-150x150.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-83_retro_Bliss_83-150x150.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-90_retro_Bliss_90-150x150.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-90_retro_Bliss_90-150x150.jpg",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//   },
// ];

// export const NewsPageProduct = [
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//     title: "RETRO BLISS",
//     desciption:
//       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet iure necessitatibus perspiciatis at quia alias incidunt. Nihil deleniti asperiores similique et harum eos vel voluptatum odit ab! Perspiciatis, fugit ipsa!",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//     title: "RETRO BLISS",
//     desciption:
//       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet iure necessitatibus perspiciatis at quia alias incidunt. Nihil deleniti asperiores similique et harum eos vel voluptatum odit ab! Perspiciatis, fugit ipsa!",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-04_retro_Bliss_04-150x150.jpg",
//     title: "RETRO BLISS",
//     desciption:
//       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet iure necessitatibus perspiciatis at quia alias incidunt. Nihil deleniti asperiores similique et harum eos vel voluptatum odit ab! Perspiciatis, fugit ipsa!",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-20_Retro_Bliss_20.jpg",
//     title: "RETRO BLISS",
//     desciption:
//       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet iure necessitatibus perspiciatis at quia alias incidunt. Nihil deleniti asperiores similique et harum eos vel voluptatum odit ab! Perspiciatis, fugit ipsa!",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-34_Retro_Bliss_34-150x150.jpg",
//     title: "RETRO BLISS",
//     desciption:
//       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet iure necessitatibus perspiciatis at quia alias incidunt. Nihil deleniti asperiores similique et harum eos vel voluptatum odit ab! Perspiciatis, fugit ipsa!",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//     title: "RETRO BLISS",
//     desciption:
//       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet iure necessitatibus perspiciatis at quia alias incidunt. Nihil deleniti asperiores similique et harum eos vel voluptatum odit ab! Perspiciatis, fugit ipsa!",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-34_Retro_Bliss_34-150x150.jpg",
//     title: "RETRO BLISS",
//     desciption:
//       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet iure necessitatibus perspiciatis at quia alias incidunt. Nihil deleniti asperiores similique et harum eos vel voluptatum odit ab! Perspiciatis, fugit ipsa!",
//   },
//   {
//     img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//     title: "RETRO BLISS",
//     desciption:
//       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet iure necessitatibus perspiciatis at quia alias incidunt. Nihil deleniti asperiores similique et harum eos vel voluptatum odit ab! Perspiciatis, fugit ipsa!",
//   },
// ];

// export const CategoryData = [
//   {
//     id: 1,
//     title: "Category1",
//     status: true,
//     subCategory: [
//       {
//         id: 1,
//         title: "Subcategory1",
//         img: "https://t2.gstatic.com/images?q=tbn:ANd9GcTrmlDIqtttypd4x4vZ6MbhyddiffL9jqQH8wJ3QRJzUVTFDwFs",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 2,
//         title: "Subcategory1",
//         img: "https://t2.gstatic.com/images?q=tbn:ANd9GcTrmlDIqtttypd4x4vZ6MbhyddiffL9jqQH8wJ3QRJzUVTFDwFs",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 3,
//         title: "Subcategory1",
//         img: "https://t2.gstatic.com/images?q=tbn:ANd9GcTrmlDIqtttypd4x4vZ6MbhyddiffL9jqQH8wJ3QRJzUVTFDwFs",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 4,
//         title: "Subcategory1",
//         img: "https://t2.gstatic.com/images?q=tbn:ANd9GcTrmlDIqtttypd4x4vZ6MbhyddiffL9jqQH8wJ3QRJzUVTFDwFs",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 5,
//         title: "Subcategory1",
//         img: "https://t2.gstatic.com/images?q=tbn:ANd9GcTrmlDIqtttypd4x4vZ6MbhyddiffL9jqQH8wJ3QRJzUVTFDwFs",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 6,
//         title: "Subcategory1",
//         img: "https://t2.gstatic.com/images?q=tbn:ANd9GcTrmlDIqtttypd4x4vZ6MbhyddiffL9jqQH8wJ3QRJzUVTFDwFs",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 2,
//     title: "Category2",
//     status: false,

//     subCategory: [
//       {
//         id: 1,
//         title: "Subcategory2",
//         img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",
//           },
//         ],
//       },
//       {
//         id: 2,
//         title: "Subcategory2",
//         img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 3,
//         title: "Subcategory2",
//         img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 4,
//         title: "Subcategory2",
//         img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 5,
//         title: "Subcategory2",
//         img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 6,
//         title: "Subcategory2",
//         img: "https://vnstatic.net/venera/big/sola-0923-chernyj-stan.jpg?v230815",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 3,
//     title: "Category3",
//     status: false,

//     subCategory: [
//       {
//         id: 1,
//         title: "Subcategory3",
//         img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-04_retro_Bliss_04-150x150.jpg",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 2,
//         title: "Subcategory3",
//         img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-04_retro_Bliss_04-150x150.jpg",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 3,
//         title: "Subcategory3",
//         img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-04_retro_Bliss_04-150x150.jpg",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 4,
//         title: "Subcategory3",
//         img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-04_retro_Bliss_04-150x150.jpg",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 5,
//         title: "Subcategory3",
//         img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-04_retro_Bliss_04-150x150.jpg",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 6,
//         title: "Subcategory3",
//         img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-04_retro_Bliss_04-150x150.jpg",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 4,
//     title: "Category4",
//     status: false,

//     subCategory: [
//       {
//         id: 1,
//         title: "Subcategory4",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_77-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 2,
//         title: "Subcategory4",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_77-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 3,
//         title: "Subcategory4",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_77-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 4,
//         title: "Subcategory4",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_77-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 5,
//         title: "Subcategory4",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_77-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 6,
//         title: "Subcategory4",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_77-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 5,
//     title: "Category5",
//     status: false,

//     subCategory: [
//       {
//         id: 1,
//         title: "Subcategory5",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_27-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 2,
//         title: "Subcategory5",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_27-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 3,
//         title: "Subcategory5",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_27-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 4,
//         title: "Subcategory5",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_27-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 5,
//         title: "Subcategory5",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_27-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//       {
//         id: 6,
//         title: "Subcategory5",
//         img: "https://5room.ru/image/cache/catalog/image/cache/catalog/Kovrolin/A-W/Spiritus/Spiritus_27-400x400.webp",

//         secondSub2Category: [
//           {
//             id: 1,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-80_retro_Bliss_80-150x150.jpg",
//           },
//           {
//             id: 2,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-02_retro_Bliss_02.jpg",
//           },
//           {
//             id: 3,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-03_retro_Bliss_03.jpg",
//           },
//           {
//             id: 4,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-77_Retro_Bliss_77-150x150.jpg",
//           },
//           {
//             id: 5,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-48_retro_Bliss_48-150x150.jpg",
//           },
//           {
//             id: 6,
//             title: "SecondSub2",
//             img: "https://www.davis.pl/wp-content/uploads/2023/01/retro-bliss-93_retro_Bliss_93-150x150.jpg",
//           },
//         ],
//       },
//     ],
//   },
// ];

// export const languages = [
//   {
//     id: 1,
//     lang: "ru",
//     image: "/img/ru.png",
//   },
//   {
//     id: 2,
//     lang: "uz",
//     image: "/img/uz.png",
//   },
// ];

export const colorsData = [
  {
    id: 1,
    name: "blue",
    image: "/img/colors/blue.webp",
  },
  {
    id: 2,
    name: "black",
    image: "/img/colors/black.webp",
  },
  {
    id: 3,
    name: "gold",
    image: "/img/colors/gold.webp",
  },
  {
    id: 4,
    name: "green",
    image: "/img/colors/green.webp",
  },
  {
    id: 5,
    name: "red",
    image: "/img/colors/red.webp",
  },
  {
    id: 6,
    name: "violet",
    image: "/img/colors/violet.webp",
  },
  {
    id: 7,
    name: "white",
    image: "/img/colors/white.webp",
  },
];

export const technologiesData = [
  {
    id: 1,
    name: "Легко чистить",
  },
  {
    id: 2,
    name: "Водоотталкивающий",
  },
  {
    id: 3,
    name: "Допускается размещение животных",
  },
  {
    id: 4,
    name: "Переработанный полиэстер",
  },
  {
    id: 5,
    name: "Базовый Дэвис",
  },
];

export const CertificateData = [
  Certificate1,
  Certificate2,
  Certificate3,
  Certificate4,
  Certificate5,
  Certificate6,
];
